import React, { FC } from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    ImageInput,
    ImageField,
    FileInput,
    FileField,
    TextInput,
    NumberInput,
    useTranslate,
    ReferenceArrayInput,
    SelectArrayInput,
    Toolbar,
    SaveButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { FieldProps, Category } from '../types';

const FileTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.files.name', { smart_count: 1 })} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

const FileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const FileEdit = (props: any) => (
    <Edit title={<FileTitle />} {...props}>
        <SimpleForm toolbar={<FileEditToolbar />}>
            <NumberInput label="Pagina" source="page" />
            <TextInput label="Titel NL" source="title" />
            <TextInput label="Titel EN" source="title_en" />
            <RichTextInput label="Informatie-beschrijving NL" source="descriptionInformation" />
            <RichTextInput label="Informatie-beschrijving EN" source="descriptionInformation_en" />
            <RichTextInput label="Opdracht-beschrijving NL" source="descriptionAssignment" />
            <RichTextInput label="Opdracht-beschrijving EN" source="descriptionAssignment_en" />
            <ImageInput source="imagesAssignment" label="Opdracht-afbeeldingen NL" multiple accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput source="imagesAssignment_en" label="Opdracht-afbeeldingen EN" multiple accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput source="imagesInformation" label="Informatie-afbeeldingen NL" multiple accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
            <ImageInput source="imagesInformation_en" label="Informatie-afbeeldingen EN" multiple accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
            <FileInput source="document" label="Bestand (PDF) NL" accept="application/pdf">
                <FileField source="src" title="title" />
            </FileInput>
            <FileInput source="document_en" label="Bestand (PDF) EN" accept="application/pdf">
                <FileField source="src" title="title" />
            </FileInput>
            <TextInput label="Wachtwoord" source="password" />
            <ReferenceArrayInput label="Profielen die opdracht zien" source="assignmentProfiles" reference="profiles">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export default FileEdit;
