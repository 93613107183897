import React from 'react';
import { Admin, Resource } from 'react-admin';
import {
    FirebaseAuthProvider,
    FirebaseDataProvider,
    FirebaseRealTimeSaga,
} from 'react-admin-firebase';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import { DB_CONFIG } from '../config.js';

import Profiles from './Profiles';
import Files from './Files';
import { Dashboard } from './Dashboard';
import { Layout } from './Layout';
import './adminPage.scss'

const AdminPage = () => {
    const i18nProvider = polyglotI18nProvider(locale => {

        // Always fallback on english
        return englishMessages;
    }, 'en');

    const options = {};

    const dataProvider = FirebaseDataProvider(DB_CONFIG, options);
    const authProvider = FirebaseAuthProvider(DB_CONFIG, options);

    return(
        <div className={"adminPage"}>
            <Admin
                dataProvider={dataProvider}
                authProvider={authProvider}
                layout={Layout}
                i18nProvider={i18nProvider}>
                <Resource name="profiles" {...Profiles} />
                <Resource name="files" {...Files} />
            </Admin>
        </div>
    )
};

export default AdminPage;
