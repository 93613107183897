import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    resources: {
       profiles: {
           name: "Profielen",
       },
        files: {
           name: "Pagina's"
       }
    },
};

export default customEnglishMessages;
