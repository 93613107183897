import BookIcon from '@material-ui/icons/Book';

import FileList from './FileList';
import FileEdit from './FileEdit';

export default {
    list: FileList,
    edit: FileEdit,
    icon: BookIcon,
};
