import React, { FC } from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    TextInput,
    useTranslate,
    SaveButton,
    Toolbar,
} from 'react-admin';

import { FieldProps, Category } from '../types';

const CategoryTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.categories.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

const ProfileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ProfileEdit = (props: any) => (
    <Edit title={<CategoryTitle />} {...props}>
        <SimpleForm toolbar={<ProfileEditToolbar />}>
            <TextInput label="Naam" source="name" />
            <TextInput label="Wachtwoord" source="password" />
        </SimpleForm>
    </Edit>
);

export default ProfileEdit;
