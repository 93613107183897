import React from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';

const FileList = (props: any) => (
    <List {...props} perPage={25} bulkActionButtons={false} sort={{ field: 'page', order: 'ASC' }}>
        <Datagrid>
            <TextField source="page" />
            <TextField source="title" />
            <EditButton />
        </Datagrid>
    </List>
);

export default FileList;
