import ProfileIcon from '@material-ui/icons/Person';

import ProfileList from './ProfileList';
import ProfileEdit from './ProfileEdit';

export default {
    list: ProfileList,
    edit: ProfileEdit,
    icon: ProfileIcon,
};
