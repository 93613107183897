import React, {Component} from 'react';
import Draggable from 'react-draggable';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import './login.scss';

class Login extends Component {
    constructor(props) {
        super(props);
        this.loginRef = React.createRef(null);

        this.state = {
            wrong: false
        }
    }

    goToFile = (id) => {
        const { history } = this.props;
        if(history) history.push(`/file/${id}`)
    };

    redirectToPage = () => {
        const currentPage = localStorage.getItem('currentPage');

        if (currentPage) {
            this.goToFile(currentPage)
        } else {
            const db = firebase.firestore();
            db.collection("files").where("page", "==", 1)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        localStorage.setItem('currentPage', doc.id);
                        this.goToFile(doc.id)
                    });
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });

        }
    };

    checkCode = (event) => {
        event.preventDefault();
        const password = event.target.elements.password.value;

        const db = firebase.firestore();
        db.collection("profiles").where("password", "==", password)
            .get()
            .then((querySnapshot) => {
                if(querySnapshot.empty) {
                    this.setState({wrong: true})
                } else {
                    querySnapshot.forEach((doc) => {
                        localStorage.setItem('profileId', doc.id);
                        localStorage.setItem('profileName', doc.data().name);

                        this.redirectToPage();
                    });
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
    };

    render() {
        const { t, i18n } = this.props;

        return(
            <div className="loginPage">
                    <div ref={this.loginRef.current} className="loginBox">
                        <div className="loginHeader">
                            <h3>{t('Online TV Game')}</h3>
                        </div>
                        <p>{t('login_with_password')}</p>
                        <form onSubmit={this.checkCode}>
                            <input name="password" style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} className="passwordInput"></input>
                            <button type="submit" className="loginBtn"><span>{t('login')}</span></button>
                        </form>
                    </div>
            </div>
        )
    }
};

export default withRouter(withTranslation()(Login));
